import React from 'react';
import styles from './info-block.module.css';
import icon from './icon.svg'
import { useTranslation } from 'react-i18next';


export const InfoBlock = () => {
	  const { t } = useTranslation()

  return (
    <section className={styles.infoBlock}>
				<div className={styles.infoBlockWrap}>
					<div className={styles.infoBlockText}>						
							<h3>
								<img className={styles.infoBlockIcon} src={icon} alt="icon" />
								{t("home.infoBlock.title")}
							</h3>
							<p>{t("home.infoBlock.subTitle")}</p>
					</div>
				</div>
    </section>
  )
}
