import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Container } from '../container';
import styles from './benefity.module.css';
import icon1 from './icons/icon1.svg';
import icon2 from './icons/icon2.svg';
import icon3 from './icons/icon3.svg';
import icon4 from './icons/icon4.svg';
import icon5 from './icons/icon5.svg';
import icon6 from './icons/icon6.svg';

export const Benefity = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.section}>
      <Container>
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <Trans i18nKey="home.benefity.title">             
          Why <span>NutritionPro?</span>
          </Trans>
        </h3>

        <div className={styles.sectionList}>
          
          <div className={styles.sectionListItem}> 
              <img className={styles.listItemIcon} src={icon1} alt="icon"/>
              <h5 className={styles.title}>
                {t("home.benefity.option1")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option1Description")}
              </p>
          </div>

          <div className={styles.sectionListItem}>
              <img className={styles.listItemIcon} src={icon2} alt="icon"/>       
              <h5 className={styles.title}>
                {t("home.benefity.option2")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option2Description")}
              </p>
          </div>

          <div className={styles.sectionListItem}>
              <img className={styles.listItemIcon} src={icon3} alt="icon"/>       
              <h5 className={styles.title}>
                {t("home.benefity.option3")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option3Description")}
              </p>
          </div>

          <div className={styles.sectionListItem}>
              <img className={styles.listItemIcon} src={icon4} alt="icon"/>       
              <h5 className={styles.title}>
                {t("home.benefity.option4")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option4Description")}
              </p>
          </div>

          <div className={styles.sectionListItem}>
              <img className={styles.listItemIcon} src={icon5} alt="icon"/>       
              <h5 className={styles.title}>
                {t("home.benefity.option5")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option5Description")}
              </p>
          </div>

          <div className={styles.sectionListItem}>
              <img className={styles.listItemIcon} src={icon6} alt="icon"/>       
              <h5 className={styles.title}>
                {t("home.benefity.option6")}
              </h5>
              <p className={styles.description}>
                {t("home.benefity.option6Description")}
              </p>           
          </div>

        </div>
      </Container>
    </section>
  )
}
