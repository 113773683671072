import './carousel.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';
import { useLangContext } from '../../utils/lang';
import { AnimatedWrapper } from '../animated-wrapper';
import { Button } from '../button';
import { Button2 } from '../button2';
import { Container } from '../container';
import { LocalizedLink } from '../localized-link';
import { FoodCard } from './food-card';
import styles from './food-carousel.module.css';
import carouselControlImg1 from './images/obed-image.png';

const FoodCardListItem = ({ text, title }) => (
  <li>
    <span className={styles.el}>{text} {title}</span>
  </li>
)

const FoodCardList = ({ children }) => (
  <ul className={styles.foodCardList}>{children}</ul>
)

const FoodCarousel = () => {
  const { t } = useTranslation()

  const slidesData = [
    {
      weekday: "1",
      img: "/images/food/food1.jpg",
      title: t("general.food3.card1"),
      label: t("general.food3.labels.breakfast"),
      param1: "340",
      param2: "41g",
      param3: "30g",
      param4: "5g",
    },
    {
      weekday: "1",
      img: "/images/food/food2.jpg",
      title: t("general.food3.card2"),
      label: t("general.food3.labels.snack1"),
      param1: "329",
      param2: "16g",
      param3: "31g",
      param4: "15g",
    },
    {
      weekday: "1",
      img: "/images/food/food3.jpg",
      title: t("general.food3.card3"),
      label: t("general.food3.labels.lunch"),
      param1: "436",
      param2: "42g",
      param3: "31g",
      param4: "14g",
    },
    {
      weekday: "1",
      img: "/images/food/food4.jpg",
      title: t("general.food3.card4"),
      label: t("general.food3.labels.snack2"),
      param1: "214",
      param2: "15g",
      param3: "14g",
      param4: "11g",
    },
    {
      weekday: "1",
      img: "/images/food/food5.jpg",
      title: t("general.food3.card5"),
      label: t("general.food3.labels.dinner"),
      param1: "306",
      param2: "31g",
      param3: "29g",
      param4: "5g",
    },
    {
      weekday: "2",
      img: "/images/food/food6.jpg",
      title: t("general.food3.card6"),
      label: t("general.food3.labels.breakfast"),
      param1: "294",
      param2: "29g",
      param3: "24g",
      param4: "7g",
    },
    {
      weekday: "2",
      img: "/images/food/food7.jpg",
      title: t("general.food3.card7"),
      label: t("general.food3.labels.snack1"),
      param1: "321",
      param2: "32g",
      param3: "27g",
      param4: "7g",
    },
    {
      weekday: "2",
      img: "/images/food/food8.jpg",
      title: t("general.food3.card8"),
      label: t("general.food3.labels.lunch"),
      param1: "250",
      param2: "35g",
      param3: "28g",
      param4: "5g",
    },
    {
      weekday: "2",
      img: "/images/food/food9.jpg",
      title: t("general.food3.card9"),
      label: t("general.food3.labels.snack2"),
      param1: "246",
      param2: "21g",
      param3: "14g",
      param4: "12g",
    },
    {
      weekday: "2",
      img: "/images/food/food10.jpg",
      title: t("general.food3.card10"),
      param1: "309",
      param2: "9g",
      param3: "29g",
      param4: "19g",
    },
    {
      weekday: "3",
      img: "/images/food/food11.jpg",
      title: t("general.food3.card11"),
      label: t("general.food3.labels.breakfast"),
      param1: "287",
      param2: "27g",
      param3: "27g",
      param4: "7g",
    },
    {
      weekday: "3",
      img: "/images/food/food12.jpg",
      title: t("general.food3.card12"),
      label: t("general.food3.labels.snack1"),
      param1: "232",
      param2: "26g",
      param3: "18g",
      param4: "7g",
    },
    {
      weekday: "3",
      img: "/images/food/food13.jpg",
      title: t("general.food3.card13"),
      label: t("general.food3.labels.lunch"),
      param1: "349",
      param2: "34g",
      param3: "23g",
      param4: "12g",
    },
    {
      weekday: "3",
      img: "/images/food/food14.jpg",
      title: t("general.food3.card14"),
      label: t("general.food3.labels.snack2"),
      param1: "308",
      param2: "46g",
      param3: "12g",
      param4: "9g",
    },
    {
      weekday: "3",
      img: "/images/food/food15.jpg",
      title: t("general.food3.card15"),
      label: t("general.food3.labels.dinner"),
      param1: "266",
      param2: "17g",
      param3: "14g",
      param4: "13g",
    },
    {
      weekday: "4",
      img: "/images/food/food16.jpg",
      title: t("general.food3.card16"),
      label: t("general.food3.labels.breakfast"),
      param1: "340",
      param2: "41g",
      param3: "30g",
      param4: "5g",
    },
    {
      weekday: "4",
      img: "/images/food/food17.jpg",
      title: t("general.food3.card17"),
      label: t("general.food3.labels.snack1"),
      param1: "329",
      param2: "16g",
      param3: "31g",
      param4: "15g",
    },
    {
      weekday: "4",
      img: "/images/food/food18.jpg",
      title: t("general.food3.card18"),
      label: t("general.food3.labels.lunch"),
      param1: "436",
      param2: "42g",
      param3: "31g",
      param4: "14g",
    },
    {
      weekday: "4",
      img: "/images/food/food19.jpg",
      title: t("general.food3.card19"),
      label: t("general.food3.labels.snack2"),
      param1: "214",
      param2: "15g",
      param3: "14g",
      param4: "11g",
    },
    {
      weekday: "4",
      img: "/images/food/food20.jpg",
      title: t("general.food3.card20"),
      label: t("general.food3.labels.dinner"),
      param1: "306",
      param2: "31g",
      param3: "29g",
      param4: "5g",
    },
    {
      weekday: "5",
      img: "/images/food/food21.jpg",
      title: t("general.food3.card21"),
      label: t("general.food3.labels.breakfast"),
      param1: "294",
      param2: "29g",
      param3: "24g",
      param4: "7g",
    },
    {
      weekday: "5",
      img: "/images/food/food22.jpg",
      title: t("general.food3.card22"),
      label: t("general.food3.labels.snack1"),
      param1: "321",
      param2: "32g",
      param3: "27g",
      param4: "7g",
    },
    {
      weekday: "5",
      img: "/images/food/food23.jpg",
      title: t("general.food3.card23"),
      label: t("general.food3.labels.lunch"),
      param1: "250",
      param2: "35g",
      param3: "28g",
      param4: "5g",
    },
    {
      weekday: "5",
      img: "/images/food/food24.jpg",
      title: t("general.food3.card24"),
      label: t("general.food3.labels.snack2"),
      param1: "246",
      param2: "21g",
      param3: "14g",
      param4: "12g",
    },
    {
      weekday: "5",
      img: "/images/food/food25.jpg",
      title: t("general.food3.card25"),
      label: t("general.food3.labels.dinner"),
      param1: "309",
      param2: "9g",
      param3: "29g",
      param4: "19g",
    },
    // {
    //   weekday: "6",
    //   img: "/images/food/food26.jpg",
    //   title: "Palačinky se sýrem a rajčaty",
    //   label: "Frühstück",
    //   param1: "287",
    //   param2: "27g",
    //   param3: "27g",
    //   param4: "7g",
    // },
    // {
    //   weekday: "6",
    //   img: "/images/food/food27.jpg",
    //   title: "Grilovaný ananas s borůvkami",
    //   label: "Imbiss",
    //   param1: "232",
    //   param2: "26g",
    //   param3: "18g",
    //   param4: "7g",
    // },
    // {
    //   weekday: "6",
    //   img: "/images/food/food28.jpg",
    //   title: "Řecká musaka",
    //   label: t("general.food3.labels.lunch"),
    //   param1: "349",
    //   param2: "34g",
    //   param3: "23g",
    //   param4: "12g",
    // },
    // {
    //   weekday: "6",
    //   img: "/images/food/food29.jpg",
    //   title: "Tabouleh z kuskusu, zeleniny, bylinek a balkánským sýrem",
    //   label: "Imbiss",
    //   param1: "308",
    //   param2: "46g",
    //   param3: "12g",
    //   param4: "9g",
    // },
    // {
    //   weekday: "6",
    //   img: "/images/food/food30.jpg",
    //   title: "Roastbeef s pečenými batáty a brokolicí",
    //   label: t("general.food3.labels.dinner"),
    //   param1: "266",
    //   param2: "17g",
    //   param3: "14g",
    //   param4: "13g",
    // },
  ]

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    // appendDots: dots => {
    //   return (
    //     <div>
    //       <ul>
    //         {dots.map((item, index) => {
    //           return <li key={index}>this is {item.props.children}</li>
    //         })}
    //       </ul>
    //     </div>
    //   )
    // },
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // dots: true,
        },
      },
    ],
  }

  const [activeTabState, setActiveTabState] = useState("1");
  const [items, setItems] = useState(slidesData);

  function changeSliderItemsState(activeTab) {
    const filteredItems = items.filter((tab) => tab.weekday === activeTab && tab);

    setItems(filteredItems);
  }

  const handleTabChange = (event) => {
    const activeTab = event.target.getAttribute("data-tab");
    setActiveTabState(activeTab);
    console.log("activeTab", activeTab);
  }

  // useEffect(() => {
  //   changeSliderItemsState(activeTabState);
  // }, [activeTabState]);

  const Slide = ({ item }) => (
    <div className={styles.carouselItem}>
      <FoodCard img={item.img} title={item.title} dataTab={item.weekday} label={item.label}>
        <FoodCardList>
          <FoodCardListItem
            text={item.param1}
            title={t("general.food2.cardInfoLabel1")}
          />
          <FoodCardListItem
            text={item.param2}
            title={t("general.food2.cardInfoLabel2")}
          />
          <FoodCardListItem
            text={item.param3}
            title={t("general.food2.cardInfoLabel3")}
          />
          <FoodCardListItem
            text={item.param4}
            title={t("general.food2.cardInfoLabel4")}
          />
        </FoodCardList>
      </FoodCard>
    </div>
  )

  const slides1 = items.map(item => item.weekday === "1" && <Slide key={item.title} item={item} />);
  const slides2 = items.map(item => item.weekday === "2" && <Slide key={item.title} item={item} />);
  const slides3 = items.map(item => item.weekday === "3" && <Slide key={item.title} item={item} />);
  const slides4 = items.map(item => item.weekday === "4" && <Slide key={item.title} item={item} />);
  const slides5 = items.map(item => item.weekday === "5" && <Slide key={item.title} item={item} />);
  // const slides6 = items.map(item => item.weekday === "6" && <Slide key={item.title} item={item} />);

  return (
    items !== null &&
    items.length > 0 && (
      <div>
        <div className={styles.foodCarouselTabs}>
          <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "1"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="1"
          >
            {t("general.food3.days.Monday")}
          </button>
          <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "2"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="2"
          >
            {t("general.food3.days.Tuesday")}
          </button>
          <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "3"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="3"
          >
            {t("general.food3.days.Wednesday")}
          </button>
          <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "4"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="4"
          >
            {t("general.food3.days.Thursday")}
          </button>
          <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "5"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="5"
          >
            {t("general.food3.days.Friday")}
          </button>
          {/* <button
            className={
              cx(styles.foodCarouselTab, {
                [styles.active]: activeTabState === "6"
              })
            }
            onClick={event => handleTabChange(event)} data-tab="6"
          >
            Sobota
          </button> */}
        </div>
        {activeTabState === "1" && <Slider {...settings}>{slides1}</Slider>}
        {activeTabState === "2" && <Slider {...settings}>{slides2}</Slider>}
        {activeTabState === "3" && <Slider {...settings}>{slides3}</Slider>}
        {activeTabState === "4" && <Slider {...settings}>{slides4}</Slider>}
        {activeTabState === "5" && <Slider {...settings}>{slides5}</Slider>}
        {/* {activeTabState === "6" && <Slider {...settings}>{slides6}</Slider>} */}
      </div>
    )
  )
}

export const FoodCarouselSection3 = () => {
  const { t } = useTranslation()
  const { lang } = useLangContext()
  const scroll = useSmoothScroll()

  const scrollToCalc = () => scroll.animateScroll(document.getElementById("calculator"))

  return (
    <>
      <section className={styles.foodCarouselSection} id="food">
        <Container isWide={true}>
          <h3 className="fancyUnderlineText sectionTitleNew text-center">
            <Trans i18nKey="home.food.title">
              <span>Welche Mahlzeiten</span> sind zu erwarten?
            </Trans>
          </h3>
          <FoodCarousel />
          <div
            className={cx("text-center", { ["hide"]: lang !== "de" })}
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <Button2 
              color="outline" 
              size="lg" 
              className={styles.outsideButton} 
              style={{justifyContent: "center"}} 
            >
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="/menu.pdf"
              target="_blank"
            >
              Siehe Menü
            </a>
          </Button2>
          </div>
        </Container>
      </section>
    </>
  )
}
