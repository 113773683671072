import React, { useEffect, useState } from 'react';
import  { Button2 } from "../button2";
import { useModal } from '../modal2';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';
import { LocalizedLink } from '../localized-link';

import styles from "./styles.module.css";
import {ToggleSwitch} from "../toggle";
import { useLangContext } from '../../utils/lang';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

export const CookieAgreementModal = ({ formName }) => {
  const { lang } = useLangContext()
  const { t } = useTranslation()

  const { show, hide, RenderModal } = useModal()
  const { show: showExtended, hide: hideExtended, RenderModal: RenderExtendedModal } = useModal()
  const [isModal, setIsModal] = useState(false);
  const [isExtendedModal, setIsExtendedModal] = useState(false);
  const scroll = useSmoothScroll()

  useEffect(() => {
    if (window.localStorage) {
      // Get the expiration date of the previous popup.
      var nextPopup = localStorage.getItem( 'cookiePopup' );
  
      if (nextPopup > new Date()) {
        return;
      }
  
      // Store the expiration date of the current popup in localStorage.
      var expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
  
      localStorage.setItem( 'cookiePopup', expires );

      setIsModal(true);
      show();
    }
  }, []);

  const showExtendedModal = () => {
    setIsExtendedModal(true);
    showExtended();
  }

  const openExtendedPopup = () => {
    setIsModal(false);
    showExtendedModal();
  }
  
  return (
    <>
      {isModal && (
        <RenderModal className={styles.cookieModal}>
              <h3 className={styles.title}>
                {t("cookies.cookieModal.title")}
              </h3>
              <p className={styles.p}>
                {t("cookies.cookieModal.text")}{" "}
                 {lang === "en" ? (
                 <LocalizedLink
                to="/cookies/en"
                target="_blank"
                rel="noopener norefferer"
              >
                {t("cookies.cookieModal.link")}
              </LocalizedLink>
                 ) : (<LocalizedLink
                to="/cookies/de"
                target="_blank"
                rel="noopener norefferer"
              >
                {t("cookies.cookieModal.link")}
              </LocalizedLink>)}
              </p>
            <div className={styles.btnsBlock}>
              <Button2
                color="secondary"
                className={styles.btn}
                handleClick={openExtendedPopup}
              >
                {t("cookies.cookieModal.btn1")}
              </Button2>
              <Button2
                color="secondary"
                className={styles.btn}
                handleClick={hide}
              >
                {t("cookies.cookieModal.btn2")}
              </Button2>
            </div>
        </RenderModal>
      )}
      {isExtendedModal && (
          <RenderExtendedModal className={styles.cookieModal}>
            <h3 className={styles.title}>
              {t("cookies.cookieModalExtended.title")}
            </h3>
            <p className={styles.p}>
             {t("cookies.cookieModalExtended.text1")}
            </p>
            <section>
              <h3 className={styles.sectionTitle}>{t("cookies.cookieModalExtended.option1")}</h3>
              <p className={styles.p}>
                {t("cookies.cookieModalExtended.option1text")}
              </p>
            </section>
            <hr className={styles.divider} />
            <section>
              <h3 className={styles.sectionTitle}>
                <span>{t("cookies.cookieModalExtended.option2")}</span>
                <ToggleSwitch label={"label 1"} />
              </h3>
              <p className={styles.p}>
                {t("cookies.cookieModalExtended.option2text")}
              </p>
            </section>
            <hr className={styles.divider} />
            <section>
              <h3 className={styles.sectionTitle}>
                <span>{t("cookies.cookieModalExtended.option3")}</span>
                <ToggleSwitch label={"label 2"} />
              </h3>
              <p className={styles.p}>
                {t("cookies.cookieModalExtended.option3text")}
              </p>
            </section>
            <div className={styles.btnsBlock}>
              <Button2
                  color="secondary"
                  className={styles.btn}
                  handleClick={hideExtended}
              >
                 {t("cookies.cookieModalExtended.btn1")}
              </Button2>
              <Button2
                  color="secondary"
                  className={styles.btn}
                  handleClick={hideExtended}
              >
                {t("cookies.cookieModal.btn2")}
              </Button2>
            </div>
          </RenderExtendedModal>
      )}
    </>
  )
}
